import {Fields, Model} from '@vuex-orm/core';

export default class ProductOption extends Model {
    public static modelName = 'ProductOption';
    public static entity = 'product-options';

    public id!: number;
    public productId!: number;
    public name!: string;
    public options!: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            productId: this.number(0),
            name: this.string(''),
            options: this.string(''),
        };
    }
}
