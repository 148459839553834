import { setToast } from '@helcim/blizzard-core';
import InputError from '../../types/InputError';
import { useEvent } from './useEvent';
import {Emitter, EventType} from 'mitt';
import {inject} from 'vue';

export function useNotificationToast () {

    const emitter: Emitter<Record<EventType, unknown>> = inject('emitter');

    const setToastNotification = (variant: string, text: string, duration?: number, position?: string): void => {
        setToast(variant, text, duration, position);
    };

    const isInputError = (input: InputError | string): input is InputError => {
        return Object.prototype.hasOwnProperty.call(input, 'field')
            && Object.prototype.hasOwnProperty.call(input, 'message');
    };

    const getToastType = (responseNumber: number): string => {
        if (responseNumber === 1) { return 'success'; }
        if (responseNumber === 0) { return 'error'; }
        if (responseNumber === 2) { return 'warning'; }
        return '';
    };

    const emitFieldErrorOrToast = (inputErrors: InputError[] | string[] | string): void => {
        if(typeof inputErrors === 'string') {
            setToastNotification('error', inputErrors, 8000, 'top-right');
            return;
        }

        for (const key in inputErrors) {
            const value = inputErrors[key];

            let id, element, text;
            if(isInputError(value)) {
                [id, element, text] = [value.field, document.querySelector(`[id="${value.field}"]`), value.message];
            } else {
                [id, element, text] = [key, document.querySelector(`[id="${key}"]`), value];
            }

            if(element) {
                const isHidden = element.getAttribute('type') === 'hidden';
                if(!isHidden) {
                    emitter.emit(`${id}ErrorMessage`, text);
                    continue;
                }
            }

            const keyAsFloat = parseFloat(key);
            if (isNaN(keyAsFloat)) {
                const keyAsIdentifier = key.replace(/([A-Z])/g, ' $1').toLowerCase();
                const keyAsSentenceCase = keyAsIdentifier.charAt(0).toUpperCase() + keyAsIdentifier.slice(1);
                setToastNotification('error', `${keyAsSentenceCase} ${text}`, 8000, 'top-right');
            } else {
                setToastNotification('error', text, 8000, 'top-right');
            }
        }
    };

    const emitNotice = (responseNumber: number, responseMessage: string, inputErrors: InputError[], duration = 8000): void => {
        if (inputErrors && Object.keys(inputErrors).length > 0) {
            emitFieldErrorOrToast(inputErrors);
        } else if (responseMessage !== '') {
            setToastNotification(getToastType(responseNumber), responseMessage, duration, 'top-right');
        }
    };

    const emitInputErrors = (inputErrors: InputError[] | string[]) => {
        const {
            emitEvent,
        } = useEvent();
        for (const key in inputErrors) {
            const value = inputErrors[key];
            if (isInputError(value)) {
                emitEvent(value.field, 'errorMessage', value.message);
            } else {
                emitEvent(key, 'errorMessage', value);
            }
        }
    };

    return {
        emitNotice,
        emitFieldErrorOrToast,
        getToastType,
        emitInputErrors,
        setToastNotification,
    };
}
