import Address from './Address';
import {Fields, Model} from '@vuex-orm/core';

export default class Merchant extends Model {
    public static modelName = 'Merchant';
    public static entity = 'merchants';

    public id!: number;
    public accountId!: number;
    public dateCreated?: string;
    public status?: number;
    public version?: number;
    public businessName!: string;
    public businessLegalName!: string;
    public taxNumber?: string;
    public addressId!: number;
    public subDomain?: string;
    public websiteURL?: string;
    public defaultTerminalId!: number;
    public defaultTerminalEFTId!: number;
    public features?: string;
    public dateClosed?: string;
    public businessId!: number;
    //temp prop:
    public address!: Address;

    public static fields(): Fields {
        return {
            id: this.attr(0),
            accountId: this.attr(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            status: this.attr(0),
            version: this.attr(0),
            businessName: this.string(''),
            businessLegalName: this.string(''),
            taxNumber: this.string(''),
            addressId: this.attr(0),
            subDomain: this.string(''),
            websiteURL: this.string(''),
            defaultTerminalId: this.attr(0),
            defaultTerminalEFTId: this.attr(0),
            features: this.string(''),
            dateClosed: this.string(''),
            businessId: this.attr(0),
            address: this.hasOne(Address, 'id', 'addressId'),
        };
    }
}
