import {Fields, Model} from '@vuex-orm/core';

export default class CartShippingDetail extends Model {

    public static modelName = 'CartShippingDetail';
    public static entity = 'cart-shipping-details';

    public id: number;
    public merchantId: number;
    public dateCreated: string;
    public dateUpdated: string;
    public methodId: number;
    public name: string;
    public amount: number;
    public type: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            methodId: this.number(0),
            name: this.number(0),
            amount: this.number(0),
            type: this.number(0),
        };
    }
}
