import {Fields, Model} from '@vuex-orm/core';
import PromoItem from './PromoItem';

export default class ItemGroup extends Model {

    public static modelName = 'ItemGroup';
    public static entity = 'item-groups';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public cartId: number;
    public promoItem: PromoItem;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            cartId: this.number(0),
            promoItem: this.hasOne(PromoItem, 'itemGroupId', 'id'),
        };
    }
}
