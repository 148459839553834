import {Fields, Model} from '@vuex-orm/core';
import Mutators from '@vuex-orm/core/dist/src/attributes/contracts/Mutators';
import {cloudFileNameFormatted} from './utilities/ModelUtilities';

export default class Image extends Model {
    public static modelName = 'Image';
    public static entity = 'images';

    public id: number;
    public dateCreated: string;
    public merchantId: number;
    public productId: number;
    public categoryId: number;
    public brandId: number;
    public serviceId: number;
    public invoiceId: number;
    public caption: string;
    public cloudFileName: string;
    public userImage: number;
    public primaryImage: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string(''),
            merchantId: this.number(0),
            productId: this.number(0),
            categoryId: this.number(0),
            brandId: this.number(0),
            serviceId: this.number(0),
            invoiceId: this.number(0),
            caption: this.string(''),
            cloudFileName: this.string(''),
            userImage: this.number(0),
            primaryImage: this.number(0),
        };
    }

    public static mutators(): Mutators {
        return {
            cloudFileName (value) {
                return cloudFileNameFormatted(value);
            },
        };
    }
}
