import {Fields, Model} from '@vuex-orm/core';

export default class CartTotal extends Model {

    public static modelName = 'CartTotal';
    public static entity = 'cart-totals';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public subTotalAmount: number;
    public discountAmount: number;
    public shippingAmount: number;
    public taxAmount: number;
    public tipsAmount: number;
    public depositAmount: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            subTotalAmount: this.number(0),
            discountAmount: this.number(0),
            shippingAmount: this.number(0),
            taxAmount: this.number(0),
            tipsAmount: this.number(0),
            depositAmount: this.number(0),
        };
    }

}
