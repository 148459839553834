import Product from './Product';
import Image from './Image';
import {Fields, Model} from '@vuex-orm/core';

export default class ProductVariant extends Model {
    public static modelName = 'ProductVariant';
    public static entity = 'product-variants';

    public id: number;
    public merchantId: number;
    public productId: number;
    public imageId: number;
    public stock: number;
    public disabled: number;
    public buyGetFree: number;
    public price: number;
    public priceByQuantity: number;
    public barcode: string;
    public salePrice: number;
    public wholesalePrice: number;
    public weight: number;
    public dimensionH: string;
    public dimensionL: string;
    public dimensionW: string;
    public sku: string;
    public optionVariant: string;
    public optionVariantHash: string;
    public product: Product;
    public image: Image;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            productId: this.number(0),
            imageId: this.number(0),
            stock: this.number(0.00),
            disabled: this.number(0),
            buyGetFree: this.number(0),
            price: this.number(0.00),
            priceByQuantity: this.number(0),
            barcode: this.string(''),
            salePrice: this.number(0.00),
            wholesalePrice: this.number(0.00),
            weight: this.number(0.00),
            dimensionH: this.string(''),
            dimensionL: this.string(''),
            dimensionW: this.string(''),
            sku: this.string(''),
            optionVariant: this.attr(''),
            optionVariantHash: this.string(''),
            product: this.hasOne(Product, 'id', 'productId'),
            image: this.hasOne(Image, 'id', 'imageId'),
        };
    }

}
