export const htmlEntityDecode = (function() {
    const parser = new DOMParser;
    function isString(data: unknown): data is string {
        return data && typeof data === 'string';
    }
    function decodeHTMLEntities (html: string) {
        if(isString(html)) {
            return parser.parseFromString(html, 'text/html').documentElement.textContent;
        }
        return '';
    }
    return decodeHTMLEntities;
})();

export const cloudFileNameFormatted = (function() {
    const isProd = import.meta.env.PROD;
    function addLocationOrigin(cloudFileName: string) {
        if (!cloudFileName) { return ''; }
        if(cloudFileName.startsWith('https://cloud-files.helcim.com/')) { return cloudFileName; }
        if(cloudFileName.startsWith('https://cloud-files.qa.helcim.com/')) { return cloudFileName; }
        if (isProd) {
            return `https://cloud-files.helcim.com/${cloudFileName}`;
        }
        return `https://cloud-files.qa.helcim.com/${cloudFileName}`;
    }
    return addLocationOrigin;
})();
