import {Fields, Model} from '@vuex-orm/core';

export default class ProductPriceRange extends Model {

    public static modelName = 'ProductPriceRange';
    public static entity = 'product-price-ranges';

    public id!: number;
    public productId!: number;
    public amount!: number;
    public quantityTo!: number;
    public quantityFrom!: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            productId: this.number(0),
            amount: this.number(0),
            quantityTo: this.number(0),
            quantityFrom: this.number(0),
        };
    }

}
