import Addon from './Addon';
import {Fields, Model} from '@vuex-orm/core';

export default class ProductAddon extends Model {
    public static modelName = 'ProductAddon';
    public static entity = 'product-addons';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public productId: number;
    public addonId: number;
    public price: number;
    public wholesalePrice: number;
    public maxPerProduct: number;
    public addon: Addon;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string(''),
            dateUpdated: this.string(''),
            merchantId: this.number(0),
            productId: this.number(0),
            addonId: this.number(0),
            price: this.number(0),
            wholesalePrice: this.number(0),
            maxPerProduct: this.number(0),
            addon: this.attr(new Addon),
        };
    }
}
