import Product from './Product';
import Image from './Image';
import {Fields, Model} from '@vuex-orm/core';
import Mutators from '@vuex-orm/core/dist/src/attributes/contracts/Mutators';
import {htmlEntityDecode} from './utilities/ModelUtilities';

export default class Category extends Model {
    public static modelName = 'Category';
    public static entity = 'categories';

    public id: number;
    public parentId: number;
    public merchantId: number;
    public lastUpdated: string;
    public name: string;
    public description: string;
    public availability: number;
    public availabilityOnline: number;
    public availabilityRetail: number;
    public backgroundColor: string;
    public featured: number;
    public images: Image[];
    public products: Product[];
    public subCategories: Category[];
    public orderCount: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            parentId: this.number(0),
            merchantId: this.number(0),
            lastUpdated: this.string('0000-00-00 00:00:00'),
            name: this.string(''),
            description: this.string(''),
            availability: this.number(1),
            availabilityOnline: this.number(1),
            availabilityRetail: this.number(1),
            backgroundColor: this.string(''),
            featured: this.number(0),
            images: this.hasMany(Image, 'categoryId'),
            products: this.hasMany(Product, 'categoryId'),
            subCategories: this.hasMany(Category, 'parentId', 'id'),
            orderCount: this.number(0),
        };
    }

    public static mutators(): Mutators {
        return {
            name (value) {
                return htmlEntityDecode(value);
            },
        };
    }
}
