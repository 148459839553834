import {Fields, Model} from '@vuex-orm/core';

export default class PromoItem extends Model {

    public static modelName = 'PromoItem';
    public static entity = 'promo-items';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public scopeId: number;
    public scopeType: number;
    public buyValue: number;
    public getValue: number;
    public getType: number;
    public getAmount: number;
    public redeemableQuantity: number;
    public redeemedQuantity: number;
    public itemGroupId: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            scopeId: this.number(0),
            scopeType: this.number(0),
            buyValue: this.number(0),
            getValue: this.number(0),
            getType: this.number(0),
            getAmount: this.number(0),
            redeemableQuantity: this.number(0),
            redeemedQuantity: this.number(0),
            itemGroupId: this.number(0),
        };
    }
}
