import {Fields, Model} from '@vuex-orm/core';
import Mutators from '@vuex-orm/core/dist/src/attributes/contracts/Mutators';
import {cloudFileNameFormatted} from './utilities/ModelUtilities';

export default class CartItem extends Model {

    public static modelName = 'CartItem';
    public static entity = 'cart-items';

    public id: number;
    public cartId: number;
    public childrenCartItems?: Record<string, CartItem>;
    public dateCreated: string;
    public dateUpdated: string;
    public netPrice: number;
    public notes: string;
    public offeringId: number;
    public offeringType: number;
    public parentId: number;
    public price: number;
    public quantity: number;
    public totalPrice: number;
    public itemGroupId: number;
    public offeringName: string;
    public offeringImageUrl?: string;
    public isClaimedItem: boolean;
    public selectedOptions?: Record<string, string>;

    public static fields(): Fields {
        return {
            id: this.number(0),
            cartId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            netPrice: this.number(0),
            notes: this.string(''),
            offeringId: this.number(0),
            offeringType: this.number(0),
            parentId: this.number(0),
            price: this.number(0),
            quantity: this.number(0),
            totalPrice: this.number(0),
            itemGroupId: this.number(0),
            offeringName: this.string(''),
            offeringImageUrl: this.string(''),
            isClaimedItem: this.boolean(false),
            selectedOptions: this.attr([]),
            childrenCartItems: this.hasMany(CartItem, 'parentId', 'id'),
        };
    }

    public static mutators(): Mutators {
        return {
            offeringImageUrl (value) {
                return cloudFileNameFormatted(value);
            },
        };
    }
}
