import { Fields, Model } from '@vuex-orm/core';
import CartTotal from './CartTotal';
import Address from './Address';
import DiscountCode from './DiscountCode';
import CartShippingDetail from './CartShippingDetail';
import { useUtilCurrency } from '../compositions';

export default class Cart extends Model {

    public static modelName = 'Cart';
    public static entity = 'carts';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public customerId: number;
    public orderId: number;
    public currencyId: number;
    public shippingAddressId: number;
    public billingAddressId: number;
    public discountCodeId: number;
    public notes: string;
    public cartTotalId: number;
    public cartShippingDetailId: number;
    public pickupTime: string;
    public cartTotal?: CartTotal;
    public shippingAddress?: Address;
    public billingAddress?: Address;
    public discountCode?: DiscountCode;
    public cartShippingDetail?: CartShippingDetail;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            customerId: this.number(0),
            orderNumber: this.number(0),
            currencyId: this.number(0),
            shippingAddressId: this.number(0),
            billingAddressId: this.number(0),
            discountCodeId: this.number(0),
            notes: this.string(''),
            cartTotalId: this.number(0),
            cartShippingDetailId: this.number(0),
            pickupTime: this.string('0000-00-00 00:00:00'),
            cartTotal: this.hasOne(CartTotal, 'id', 'cartTotalId'),
            shippingAddress: this.attr(null, (v) => {
                if(v) { Address.insertOrUpdate({data: v}).then(); }
                return null;
            }),
            billingAddress: this.attr(null, (v) => {
                if(v) { Address.insertOrUpdate({data: v}).then(); }
                return null;
            }),
            discountCode: this.hasOne(DiscountCode, 'id', 'discountCodeId'),
            cartShippingDetail: this.hasOne(CartShippingDetail, 'id', 'cartShippingDetailId'),
        };
    }

    public get currencyAbbreviation(): string {
        const { getAbbreviation } = useUtilCurrency();
        return getAbbreviation(this.currencyId);
    }

}
