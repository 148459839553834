import {Fields, Model} from '@vuex-orm/core';

export default class DiscountCode extends Model {
    public static modelName = 'DiscountCode';
    public static entity = 'discount-codes';

    public id!: number;
    public merchantId!: number;
    public dateCreated!: string;
    public dateStart!: string;
    public dateExpire!: string;
    public enabled!: number;
    public name!: string;
    public code!: string;
    public usageLimit!: number;
    public customerLimit!: number;
    public type!: number;
    public amount!: number;
    public orderMinimum!: number;
    public customerId!: number;
    public productId!: number;
    public productVariantId!: number;
    public categoryId!: number;
    public brandId!: number;
    public recurringPlanId!: number;
    public includeSubCategories!: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateStart: this.string('0000-00-00 00:00:00'),
            dateExpire: this.string('0000-00-00 00:00:00'),
            enabled: this.number(0),
            name: this.string(''),
            code: this.string(''),
            usageLimit: this.number(0),
            customerLimit: this.number(0),
            type: this.number(0),
            amount: this.number(0),
            orderMinimum: this.number(0),
            customerId: this.number(0),
            productId: this.number(0),
            productVariantId: this.number(0),
            categoryId: this.number(0),
            brandId: this.number(0),
            recurringPlanId: this.number(0),
            includeSubCategories: this.number(0),
        };
    }
}
