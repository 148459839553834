export function useUtilNum() {

    function displayDouble(value: number|string, numDecimals = 2): string {
        const valueAsNumber = typeof value === 'string' ? parseFloat(value) : value;

        if (typeof valueAsNumber !== 'number' || isNaN(valueAsNumber)) {
            throw new Error('Value passed is not a number');
        }

        const thousandSeparator = /\B(?=(\d{3})+(?!\d))/g;
        return valueAsNumber.toFixed(numDecimals).replace(thousandSeparator, ',');
    }

    return {
        displayDouble,
    };

}
