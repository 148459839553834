import {Fields, Model} from '@vuex-orm/core';

export default class Addon extends Model {
    public static modelName = 'Addon';
    public static entity = 'addons';

    public id: number;
    public dateCreated: string;
    public dateUpdated: string;
    public merchantId: number;
    public categoryId: number;
    public name: string;
    public defaultPrice: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            dateCreated: this.string(''),
            dateUpdated: this.string(''),
            merchantId: this.number(0),
            categoryId: this.number(0),
            name: this.string(''),
            defaultPrice: this.number(0),
        };
    }
}
