import {useUtilNum} from './useUtilNum';
import {CURRENCY, CURRENCY_CODE, CURRENCY_SYMBOL} from '../../scripts/constants';

export function useUtilCurrency(currency = CURRENCY.CAD) {

    const {
        displayDouble,
    } = useUtilNum();

    function displayWithCurrency(value: number|string, showDecimal = true): string {
        const symbol = CURRENCY_SYMBOL[currency];
        const amount = showDecimal ? displayDouble(value) : displayDouble(value, 0);
        return `${symbol}${amount}`;
    }

    function displayWithCurrencyCode(value: number|string, showDecimal = true): string {
        const amountWithCurrency = displayWithCurrency(value, showDecimal);
        const code = CURRENCY_CODE[currency];
        return `${amountWithCurrency} ${code}`;
    }

    function getAbbreviation(id: number): string {
        return CURRENCY_CODE[id] ? CURRENCY_CODE[id] : '';
    }

    return {
        displayWithCurrency,
        displayWithCurrencyCode,
        getAbbreviation,
    };
}
