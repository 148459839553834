import { inject } from 'vue';
import { Emitter, EventType } from 'mitt';

export function useEvent () {

    const emitter: Emitter<Record<EventType, unknown>> = inject('emitter');

    const emitterEmit = (eventName: string, data: unknown) => {
        emitter.emit(eventName, data);
    };

    const emitterOn = (eventName: string, handler: unknown) => {
        emitter.on(eventName, () => handler);
    };

    const emitterOff = (eventName: string, handler: unknown) => {
        emitter.off(eventName, () => handler);
    };

    const emitEvent = (elementId: string, eventName: string, message: unknown): void => {
        const event = new CustomEvent(eventName, message);
        const element: HTMLElement = document.getElementById(elementId);
        if (element === null) {
            const message = 'Invalid DOM element for ' + elementId;
            throw new DOMException(message);
        }
        element.dispatchEvent(event);
    };

    return {
        emitterEmit,
        emitterOn,
        emitterOff,
        emitEvent,
    };
}
