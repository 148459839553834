import {Fields, Model} from '@vuex-orm/core';
import Address from './Address';

export default class Customer extends Model {
    public static modelName = 'Customer';
    public static entity = 'customers';

    public id!: number;
    public merchantId!: number;
    public defaultCard?: number;
    public defaultLanguage?: number;
    public defaultBankAccount?: number;
    public customerCode?: string;
    public userGoogleId?: string;
    public businessName?: string;
    public contactName?: string;
    public billingAddressId?: number;
    public shippingAddressId?: number;
    public dateCreated?: string;
    public dateUpdated?: string;
    public createdBy?: number;
    public password?: string;
    public passwordExpiry?: string;
    public loginStatus?: number;
    public loginAttempts?: number;
    public cellPhone?: string;
    public lastIpAddress?: string;
    public lastPIN?: string;
    public lastLoginDate?: string;
    public lastLoginDatePreUpdate?: string;
    public acceptsMarketing?: number;
    public taxExempt?: number;
    public wholesale?: number;
    public vatNumber?: string;
    public passwordExists?: boolean;
    public shippingAddress?: Address;
    public billingAddress?: Address;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            defaultCard: this.number(0),
            defaultLanguage: this.number(0),
            defaultBankAccount: this.number(0),
            customerCode: this.string(''),
            userGoogleId: this.string(''),
            businessName: this.string(''),
            contactName: this.string(''),
            billingAddressId: this.number(0),
            shippingAddressId: this.number(0),
            dateCreated: this.string(''),
            dateUpdated: this.string(''),
            createdBy: this.number(0),
            password: this.string(''),
            passwordExpiry: this.string(''),
            loginStatus: this.number(0),
            loginAttempts: this.number(0),
            cellPhone: this.string(''),
            lastIpAddress: this.string(''),
            lastPIN: this.string(''),
            lastLoginDate: this.string(''),
            lastLoginDatePreUpdate: this.string(''),
            acceptsMarketing: this.number(0),
            taxExempt: this.number(0),
            wholesale: this.number(0),
            vatNumber: this.string(''),
            passwordExists: this.boolean(false),
            shippingAddress: this.hasOne(Address, 'id', 'shippingAddressId'),
            billingAddress: this.hasOne(Address, 'id', 'billingAddressId'),
        };
    }

}
