import {Fields, Model} from '@vuex-orm/core';

export default class BuyGetPromotion extends Model {
    public static modelName = 'BuyGetPromotion';
    public static entity = 'buy-get-promotions';

    public id!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public merchantId!: number;
    public scopeId!: number;
    public scopeType!: number;
    public enabled!: number;
    public startDate!: string;
    public expiryDate!: string;
    public orderUsageLimit!: number;
    public buyQuantity!: number;
    public buyType!: number;
    public getQuantity!: number;
    public getType!: number;
    public getAmount!: number;
    public restrictionType!: number;

    public static fields(): Fields {
        return{
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            scopeId: this.number(0),
            scopeType: this.number(0),
            enabled: this.number(0),
            startDate: this.string('0000-00-00 00:00:00'),
            expiryDate: this.string('0000-00-00 00:00:00'),
            orderUsageLimit: this.number(0),
            buyQuantity: this.number(0),
            buyType: this.number(0),
            getQuantity: this.number(0),
            getType: this.number(0),
            getAmount: this.number(0.00),
            restrictionType: this.number(0),
        };
    }
}
