import {Fields, Model} from '@vuex-orm/core';

export default class Address extends Model {
    public static modelName = 'Address';
    public static entity = 'addresses';

    public id: number;
    public contactName: string;
    public businessName: string;
    public street1: string;
    public street2: string;
    public city: string;
    public province: string;
    public country: string;
    public postalCode: string;
    public phone: string;
    public fax: string;
    public email: string;
    public dateCreated: string;
    public dateUpdated: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            contactName: this.string(''),
            businessName: this.string(''),
            street1: this.string(''),
            street2: this.string(''),
            city: this.string(''),
            province: this.string(''),
            country: this.string(''),
            postalCode: this.string(''),
            phone: this.string(''),
            fax: this.string(''),
            email: this.string(''),
            dateCreated: this.string('00-00-00 00:00:00'),
            dateUpdated: this.string('00-00-00 00:00:00'),
        };
    }
}
