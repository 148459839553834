import {Fields, Model} from '@vuex-orm/core';

export default class ImageFile extends Model {
    public static modelName = 'ImageFile';
    public static entity = 'imageFiles';

    public id: number;
    public fileName: string;
    public fileUrl: string;

    public static fields(): Fields {
        return {
            id: this.number(0),
            fileName: this.string(''),
            fileUrl: this.string(''),
        };
    }
}