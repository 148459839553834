import ProductVariant from './ProductVariant';
import ProductPriceRange from './ProductPriceRange';
import BuyGetPromotion from './BuyGetPromotion';
import ProductOption from './ProductOption';
import ProductAddon from './ProductAddon';
import Image from './Image';
import {Fields, Model} from '@vuex-orm/core';
import ProductPricing from './ProductPricing';
import Mutators from '@vuex-orm/core/dist/src/attributes/contracts/Mutators';
import {htmlEntityDecode} from './utilities/ModelUtilities';

export default class Product extends Model {
    public static modelName = 'Product';
    public static entity = 'products';

    public id: number;
    public merchantId: number;
    public lastUpdated: string;
    public categoryId: number;
    public brandId: number;
    public name: string;
    public description: string;
    public sku: string;
    public barcode: string;
    public availability: number;
    public availabilityOnline: number;
    public availabilityRetail: number;
    public featured: number;
    public stock: number;
    public stockType: number;
    public price: number;
    public salePrice: number;
    public wholesalePrice: number;
    public enableWholesale: number;
    public cost: number;
    public deposit: number;
    public dateCreated: string;
    public weight: number;
    public dimensionW: number;
    public dimensionL: number;
    public dimensionH: number;
    public shippingExempt: number;
    public taxExempt: number;
    public variantStatus: number;
    public backgroundColor: string;
    public trackInventory: number;
    public lowStockThreshold: number;
    public unitOfMeasure: string;
    public commodityCode: string;
    public seoURL: string;
    public seoDescription: string;
    public seoTags: string;
    public reviewScore: number;
    public productVariants: ProductVariant[];
    public productPricing: ProductPricing;
    public productPriceRanges: ProductPriceRange[];
    public buyGetPromotion: BuyGetPromotion;
    public productOptions: ProductOption[];
    public productAddons: ProductAddon[];
    public images: Image[];
    public size: number;
    public index: number;

    public static fields(): Fields {
        return {
            id: this.number(0),
            merchantId: this.number(0),
            lastUpdated: this.string('0000-00-00 00:00:00'),
            categoryId: this.number(0),
            brandId: this.number(0),
            name: this.string(''),
            description: this.string(''),
            sku: this.string(''),
            barcode: this.string(''),
            availability: this.number(0),
            availabilityOnline: this.number(0),
            availabilityRetail: this.number(0),
            featured: this.number(0),
            stock: this.number(0.00),
            stockType: this.number(0),
            price: this.number(0.00),
            salePrice: this.number(0.00),
            wholesalePrice: this.number(0.00),
            enableWholesale: this.number(0),
            cost: this.number(0.00),
            deposit: this.number(0.00),
            dateCreated: this.string('0000-00-00 00:00:00'),
            weight: this.number(0.00),
            dimensionW: this.number(0.00),
            dimensionL: this.number(0.00),
            dimensionH: this.number(0.00),
            shippingExempt: this.number(0),
            taxExempt: this.number(0),
            variantStatus: this.number(0),
            backgroundColor: this.string(''),
            trackInventory: this.number(0),
            lowStockThreshold: this.number(0),
            unitOfMeasure: this.string(''),
            commodityCode: this.string(''),
            seoURL: this.string(''),
            seoDescription: this.string(''),
            seoTags: this.string(''),
            reviewScore: this.number(0),
            productVariants: this.hasMany(ProductVariant, 'productId'),
            productPricing: this.hasOne(ProductPricing, 'productId'),
            productPriceRanges: this.hasMany(ProductPriceRange, 'productId'),
            buyGetPromotion: this.hasOne(BuyGetPromotion, 'scopeId'),
            productOptions: this.hasMany(ProductOption, 'productId'),
            productAddons: this.hasMany(ProductAddon, 'productId'),
            images: this.hasMany(Image, 'productId'),
        };
    }

    public get decodedDescription(): string {
        return htmlEntityDecode(this.description);
    }

    public static mutators(): Mutators {
        return {
            name (value) {
                return htmlEntityDecode(value);
            },
        };
    }
}
