import {Fields, Model} from '@vuex-orm/core';

export default class ProductPricing extends Model {
    public static modelName = 'ProductPricing';
    public static entity = 'product-pricings';

    public id!: number;
    public dateCreated!: string;
    public dateUpdated!: string;
    public merchantId!: number;
    public productId!: number;
    public wholeSaleIncludeAddons!: number;
    public buyGetFree!: number;
    public buyQuantity!: number;
    public getQuantity!: number;
    public buyGetLimit!: number;
    public buyGetLimitProduct!: number;
    public buyGetLimitVariant!: number;
    public rangedPricing!: number;
    public rangedLimitVariants!: number;

    public static fields(): Fields {
        return{
            id: this.number(0),
            dateCreated: this.string('0000-00-00 00:00:00'),
            dateUpdated: this.string('0000-00-00 00:00:00'),
            merchantId: this.number(0),
            productId: this.number(0),
            wholeSaleIncludeAddons: this.number(0),
            buyGetFree: this.number(0),
            buyQuantity: this.number(0),
            getQuantity: this.number(0),
            buyGetLimit: this.number(0),
            buyGetLimitProduct: this.number(0),
            buyGetLimitVariant: this.number(0),
            rangedPricing: this.number(0),
            rangedLimitVariants: this.number(0),
        };
    }
}
